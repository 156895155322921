import mainjson from '../config/main';
import { SetFieldValue } from '../utils/FormFieldMethods';


function checkEdit(data){
    if (window.location.href.indexOf("be_theme_id") != -1) {
        return true;
    }
    if (data.point_theme_id) {
        return false;
    }
    else {
        return true;
    }
}

function checkDelete(data){
    if (window.location.href.indexOf("be_theme_id") != -1) {
        return true;
    }
    if (data.point_theme_id) {
        return false;
    }
    else {
        return true;
    }
}

function copyData(formdata, data,setfunc){
    //console.log("copy data",data,setfunc);
    data.data["point_name_adult"] = data.data["point_name"];
    data.data["point_text_adult"] = data.data["point_text"];
    data.data["point_image_adult"] = data.data["point_image"];
    setfunc(data);
    alert("data copied")
}

function checkSection(val){
    if (val === 0 || val === "0") {
        return false;
    }
    return true;
}


export default function GetData() {
        return {
        "apitype":"unplottedpoints",
        "id":"point_id",
        "subidname":"point_name",
        "options":["edit","insert","delete","listoptions"],
        "nameSingle":"unplotted point",
        "nameMultiple":"unplotted points",
        "export": [
            {
                name:"Number of point",
                field:"point_unplotted_point_index",
            },
            {
                name:"Master point",
                field:"point_unplotted_point_master",
            },
            {
                name:"Name",
                field:"point_name",
            },
            {
                name:"Text",
                field:"point_text",
            },
            {
                name:"Image",
                field:"point_image",
            },
            {
                name:"Audio",
                field:"point_audio",
            },
            {
                name:"Video",
                field:"point_video",
            },
            {
                name:"Image ID",
                field:"point_image_id",
            },
            {
                name:"Internal ID",
                field:"point_id",
            } 
        ],
        "editIf":checkEdit,
        "deleteIf":checkDelete,
        "fields":{
            "field0":{
                "name":"Id",
                "field":"point_id",
                "type":"Display",
                "list":true,
            },

            "field01":{
                "name":"Number of point",
                "field":"point_unplotted_point_index",
                "type":"TextInput",
                "subtype":"Number",
                "list":true,
                "required":true,
            },

            "field02":{
                "name":"Master point",
                "field":"point_unplotted_point_master",
                "type":"CheckBox",
                "list":true,
            },
         
            "field1":{
                "name":"Name",
                "field":"point_name",
                "type":"TextInput",
                "list":true,
            },
            "field1c":{
                "name":"Media",
                "field":"point_media",
                "type":"Display",
                "list":true,
                "edit":false,
         
            },     
            "field7":{
                "name":"Text",
                "field":"point_text",
                "type":"TextArea",
            }, 
            "field8":{
                "name":"OGG",
                "field":"point_audio",
                "type":"SoundUpload",
            },
            "field9":{
                "name":"Image",
                "field":"point_image",
                "type":"ImageSelect",
                "idfield":"point_image_id",
                "resize":{
                    width:1024,
                    height:576,
                    type:"fit",
                    quality:0.85,
                    bgcolor:"#ffffff"
                },
            },
            "field10":{
                "name":"Video URL",
                "field":"point_video",
                "type":"TextInput",
                "required":false,
            },
            "field14":{
                "name":"Game",
                "field":"point_game_id",
                "type":"DropDown",
                "filter":true,
                "options": "games",
                "required":false,
                "list":false,
            },
            "field15":{
                "name":"In Theme",
                "field":"point_theme_id",
                "type":"DropDown",
                "options": "themes",
                "required":false,
                "list":true,
                "edit":false,
            },
            "field16":{
                "name":"Likes/Dislikes",
                "field":"point_likesdislikes",
                "type":"Display",
                "list":true,
            },
        }
    }
}